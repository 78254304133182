import Store from '../store/store'
import Router from '../router';


//enumerable per gli snack

export const Snack = {
    success: 0,
    error: 1,
    cancel: 2,
    nothing: 4,
    remote: 5
}

export const callService = async function (service, param, noStaticParam) {
    let serviceStatic = [service];
    let paramList = "";
    if (noStaticParam) {
        paramList = (serviceStatic.concat(param)).join("/");
    }
    else {
        let paramStatic = [Store.state.partition, Store.state.utente.username, Store.state.utente.token];
        paramList = (serviceStatic.concat(paramStatic).concat(param)).join("/");
    }

    const start = Date.now();
    const response = await fetch(paramList, 
        {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Store.state.accessToken,
              }
        }
    );
    const end = Date.now();
    let time = end - start;
    console.log("TIME EXECUTION - "+service+" : "+time+" ms");

    //errore di login/accesso

    if (response.status >= 400 && response.status <= 499) {
        logout();
        return null;
    }

    //errore applicativo

    if (response.status >= 500) {
        return false;
    };

    const data = await response.json();
    return data;
};

export const callPost = async function (service, paramJson, byPassError) {

    const start = Date.now();

    const response = await fetch(service, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + Store.state.accessToken,
          },
        body: JSON.stringify(paramJson)
    });

    const end = Date.now();
    let time = end - start;
    console.log("TIME EXECUTION POST - " + service + " : " + time + " ms");

    //errore di login/accesso

    if (response.status >= 400 && response.status<=499) {
        if (byPassError) {
            return null;
        }
        else {
            logout();
            return;
        }
    };

    //errore applicativo

    if (response.status >= 500) {
        return false;
    };

    const data = await response.json();
    return data;
};

export const callPostMultipart = async function (service, param, byPassError) {

    const start = Date.now();

    const response = await fetch(service, {
        method: 'post',
        body: param
    });

    const end = Date.now();
    let time = end - start;
    console.log("TIME EXECUTION POST MULTIPART - " + service + " : " + time + " ms");    

    //errore di login/accesso

    if (response.status >= 400 && response.status <= 499) {
        if (byPassError) {
            return null;
        }
        else {
            logout();
            return;
        }
    };

    //errore applicativo

    if (response.status >= 500) {
        return false;
    };

    const data = await response.json();
    return data;
};

export const euro = function (importo) {
    if (isNaN(importo)) return "-";
    if (importo == 0) return "0,00  €"; //evita il -0
    return (Number(importo)).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })
};

export const gLocalData = function (stringDate) {
    if (!stringDate) return null;
    var d = new Date(stringDate);
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    let year = d.getFullYear();
    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    return [day, month, year].join('/');
};

export const gLocalDateTime = function (stringDate) {
    if (!stringDate) return null;
    var d = new Date(stringDate);
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    let year = d.getFullYear();
    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    let hh = d.getHours().toString();
    if (hh.length < 2) hh = '0' + hh;
    let mm = d.getMinutes().toString();
    if (mm.length < 2) mm = '0' + mm;
       
    return [day, month, year].join('/')+ " "+hh+":"+mm;
};

export const gDataCalendar = function (stringDate) {
    if (!stringDate) return null;
    var d = new Date(stringDate);
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    let year = d.getFullYear();
    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    return [year, month, day].join('-');
};

export const gControllData = function (d) {
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    let year = d.getFullYear();
    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    return [year, month, day].join('-');
};

export const gCheckData = function (d) {
    var tok = d.split("/");
    var dIso = new Date([tok[2], tok[1], tok[0]].join("-"));
    return !isNaN(dIso);
};

export const gServerData = function (stringDate) {
    //da gg/mm/aaaa a aaaa-mm-gg//

    if (!stringDate) return null;
    var tok = stringDate.split("/");
    return [tok[2], tok[1], tok[0]].join('/');
};

export const gServer2Data = function (stringDate) {
    //da aaaa-mm-gg a aaaa-mm-gg//

    if (!stringDate) return null;
    var tok = stringDate.split("-");
    var tok1 = "01";
    if (tok[2])
    {
        var tk1 = tok[2].split("T");
        tok1 = tk1[0];
    }
    var appo = [tok[0], tok[1], tok1].join('/');
    return appo;
};

export const logout = function () {
    Store.dispatch('logout');
    Store.dispatch('setUtente', null);
    Router.replace({ name: "login" });
};

export const gSynch1 = async function () {
    let param = [];
    let confApp = await callService("api/lplApp", param);
    Store.state.lplApp = confApp.lplApp;
    Store.state.isAlert = confApp.isAlert;
    Store.state.isExpired = confApp.isExpired;
    Store.state.isLocked = confApp.isLocked;
    Store.state.sourceLogo = confApp.sourceLogo;
    Store.state.studioName = confApp.lplApp.denominazione;
    Store.state.listaTipoRecapito = await callService("api/tiporecapito", param);
    Store.state.listaTipoCliente = await callService("api/tipocliente", param);
    Store.state.listaTipoScadenza = await callService("api/tiposcadenza", param);
    Store.state.listaTipoIndirizzario = await callService("api/tipoindirizzario", param);
    //
    Store.state.parametri = await callService("api/configurazione/parametri", param);
};

export const gSynch2 = async function () {
    let param = [];
    Store.state.listaTipoAttivita = await callService("api/tipoattivita", param);
    Store.state.listaOperatore = await callService("api/operatore/listaoperatoricompleta", param);
    Store.state.listaOperatoriProfessionisti = await callService("api/professionista/listaprofessionisticompleta", param);
    Store.state.listaPeriodicitaContabile = await callService("api/periodo/tipoperiodicitaconscont", param);
    Store.state.listaPeriodicitaServizi = await callService("api/periodo/tipoperiodicitaservizi", param);
    Store.state.listaModuliArPdf = await callService("api/antiriciclaggio/listamoduli", param);
};

export const gSynch3 = async function () {
    let param = [];
    Store.state.listaPeriodicitaLavoro = await callService("api/periodo/tipoperiodicitaconslav", param);
    Store.state.listaTipoPagamento = await callService("api/tipopagamento", param);
    Store.state.listaTipoConsulenza = await callService('api/tipoconsulenza', param);
    Store.state.listaTipoRegimeIVA = await callService("api/tiporegimeiva", param);
    Store.state.listaTipoRegimeContabile = await callService("api/tiporegimecontabile", param);
    //tabelle PUBLIC
    Store.state.listaComuni = await callService('api/tabelleistat/listacomuni', param);
    Store.state.listaStati = await callService('api/tabelleistat/listastati', param);
    Store.state.listaTipiAdeguataVerifica = await callService("api/antiriciclaggio/listaTAV", param);
    Store.state.listaTipiDocumentoIndentificativo = await callService("api/antiriciclaggio/listaTDI", param);
    Store.state.listaTipiFileAntiriciclaggio = await callService("api/antiriciclaggio/listaTFA", param);
    Store.state.listaTipiPrestazioneRischioInerente = await callService("api/antiriciclaggio/listaTPRI", param);
    Store.state.listaTVRS = await callService("api/antiriciclaggio/listaTVRS", param);
    Store.state.listaTVRI = await callService("api/antiriciclaggio/listaTVRI", param);
    Store.state.listaTVV = await callService("api/antiriciclaggio/listaTVV", param);
};

export const gSynch4 = async function () {
    let param = [];
    Store.state.utenti = await callService('api/utente', param);
    Store.state.utentiClienti = await callService('api/utente/listaUtentiClientiApp', param);
    Store.state.contattiEstesi = await callService('api/cliente/listaContattiEstesa', param);
};

export const gSynch5 = async function () {
    let param = [Store.state.idAnnoContabile];
    Store.state.clientiEstesi = await callService('api/cliente/listaestesa', param);
};